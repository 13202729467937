'use strict';

import React from 'react';

import Utils from '../../lib/Utils';

import Player from './Player';

export default class Match extends React.Component{
    constructor(){
        super();
    }

    getClassNames(){
        const {match} = this.props;
        const compName = Utils.getCompName(this);
        let classNames = [compName];
        if (match && match.bracket == 'gf') {
            classNames.push('Match-grandFinals')
        }
        return classNames.join(' ')
    }

    getMatch(){
        const {match} = this.props;
        let returnMatch;
        if (match) {
            returnMatch = JSON.parse(JSON.stringify(match));
        }
        else {
            returnMatch = {
                name: '',
                players: [
                    {name:'Player 1', score: 0, bracket: 'W'},
                    {name:'Player 2', score: 0, bracket: 'W'},
                ],
                swappedSides: false,
            }
        }
        return returnMatch;
    }

    render() {
        const match = this.getMatch();
        const className = this.getClassNames();
        let players = match.players;

        if (match.swappedSides) {
            players.reverse();
        }

        return (
            <div className={className}>
                <div id="Match-title"><span>{match.name}</span></div>
                <div className="Match-players">
                    <Player id="Player1" player={players[0]} />
                    <Player id="Player2" player={players[1]} />
                </div>
            </div>
        );
    }
}
