'use strict';

import React from 'react';

import Match from './Match';
import Loading from '../Loading';

export default class PlayingView extends React.Component{

    render () {
        let { stream } = this.props;
        if(stream){
            return (
                <div className="PlayingView">
                    <Match match={ stream.streamCurrentMatch } />
                    <div id="Logo" className="PlayingView-logo"></div>
                </div>
            );
        }
        else{
            return (
                <Loading />
            )
        }
    }
}
