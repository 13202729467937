"use strict";

import React from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import * as StreamActions from "../actions/StreamActions";
import Options from "./Options";
import NoStream from "./NoStream";

@connect(state => ({ appStore: state }))
export default class App extends React.Component {
  constructor() {
    super();
    this.state = {
      visible: false
    };
  }

  getUserStream(userId, streamId) {
    let { dispatch, appStore } = this.props;
    let version;
    if (appStore.stream && appStore.stream.event) {
      version = appStore.stream.event.version;
    }
    dispatch(StreamActions.requestUserStream(userId, streamId, version)).then(
      gotIt => {
        if (gotIt) {
          setTimeout(() => {
            this.getUserStream(userId, streamId, version);
          }, 4000);
        } else {
          setTimeout(() => {
            this.getUserStream(userId, streamId, version);
          }, 10000);
        }
      }
    );
  }

  getStreamClassNames(stream) {
    console.log(stream);

    let classNames = ["Stream"];
    if (stream.event) {
      classNames.push("Stream-EventId-" + stream.event.id);
      if (stream.event.slug) {
        classNames.push("Stream-Event-" + stream.event.slug);
      }
      if (stream.event.link) {
        classNames.push("Stream-Event-" + stream.event.link);
      }
    } else {
      classNames.push("Stream-NoEvent");
    }
    if (stream.streamGame) {
      classNames.push("Stream-Game-" + stream.streamGame.slug);
    }
    if (this.state.visible) {
      classNames.push("Stream-Visible");
    } else {
      classNames.push("Stream-Hidden");
    }
    return classNames.join(" ");
  }

  componentDidMount() {
    let userId = this.props.params.userId;
    let streamId = this.props.params.streamId;
    if (userId && streamId) {
      this.getUserStream(userId, streamId);
    }

    let visProp = this.getHiddenProp();

    this.visChange = () => {
      if (this.props.stream) {
        // console.log(this.props.stream);
      }
      if (this.isHidden())
        this.setState({
          visible: false
        });
      else
        this.setState({
          visible: true
        });
    };

    if (visProp) {
      const evtname = visProp.replace(/[H|h]idden/, "") + "visibilitychange";
      document.addEventListener(evtname, this.visChange);
    }
  }

  getHiddenProp() {
    var prefixes = ["webkit", "moz", "ms", "o"];

    // if 'hidden' is natively supported just return it
    if ("hidden" in document) return "hidden";

    // otherwise loop over all the known prefixes until we find one
    for (var i = 0; i < prefixes.length; i++) {
      if (prefixes[i] + "Hidden" in document) return prefixes[i] + "Hidden";
    }

    // otherwise it's not supported
    return null;
  }

  isHidden() {
    var prop = this.getHiddenProp();
    if (!prop) return false;

    return document[prop];
  }

  componentWillReceiveProps(nextProps) {
    const stream = nextProps.appStore.stream;
    if (stream.event) {
      this.setState({
        visible: true
      });
    }
  }

  render() {
    const { stream } = this.props.appStore;
    console.log(this.props.appStore);
    console.log("app");
    const { userId, streamId } = this.props.params;
    let streamClasses = this.getStreamClassNames(stream);
    if (!this.props.children) {
      return (
        <div className={streamClasses}>
          <Options stream={stream} />
        </div>
      );
    } else {
      return (
        <div className={streamClasses}>
          {React.cloneElement(this.props.children, {
            appStore: this.props.appStore,
            stream
          })}
        </div>
      );
    }
    if (stream) {
    } else {
      return (
        <div className={streamClasses}>
          <NoStream />
        </div>
      );
    }
  }
}
