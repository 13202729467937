'use strict';

import React from 'react';


export default class Loading extends React.Component{

    constructor (){
        super();
    }

    render () {
        return(
            <div className="loading-comp">
                <h1>Loading...</h1>
            </div>
        )
    }
}
