'use strict';

import React from 'react';

import Results from './Results';
import Loading from '../Loading';

export default class ResultsView extends React.Component{

    render () {
        let { stream } = this.props.appStore;
        if(stream){
            return (
                <div className="placing-view">
                    <Results players={ stream.streamTournament.players } />
                </div>
            );
        }
        else{
            return (
                <div className="placing-view">
                    <Loading />
                </div>
            )
        }
    }
}
