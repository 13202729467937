"use strict";

import React from "react";

import Utils from "../../lib/Utils";

export default class Player extends React.Component {
  constructor() {
    super();
  }

  getClassNames(player) {
    const { className } = this.props;
    const compName = Utils.getCompName(this);
    let classNames = [compName];
    if (className) {
      classNames.push(className);
    }
    classNames.push("Player-bracket" + player.bracket);
    if (player.countryCode) {
      classNames.push("Player-hasFlag");
    }
    return classNames.join(" ");
  }

  render() {
    let score = 0;
    let flag = <div className="Player-brandContainer" />;
    const { player, id } = this.props;
    const classNames = this.getClassNames(player);

    if (player.score || player.score === 0) {
      score = player.score;
    }
    if (player.countryCode) {
      let style = {
        backgroundImage: 'url("/img/flags/' + player.countryCode + '.png")'
      };
      flag = (
        <div className="Player-brandContainer">
          <div
            style={style}
            className={"Player-flag Player-flag-" + player.countryCode}
          />
        </div>
      );
    }
    return (
      <div className={classNames} id={id}>
        {flag}
        <div className="Player-name">
          {player.name}
          <span className="Player-bracket">{player.bracket}</span>
        </div>
        <div className="Player-scoreContainer">
          <div className="Player-score">{score}</div>
        </div>
      </div>
    );
  }
}
