import games from '../collection/games.json';

export default class config {
  static version () {
    return 2;
  }

  static apiUrl () {
    const apiUrl = "http://localhost:8080/";
    return apiUrl;
  }

  static colors () {
    return colors;
  }

  static latinMap () {
    return latinMap;
  }

  static countries () {
    return countries;
  }

  static gameMap () {
    const gamesMap = {};
    games.map(game => {
      gamesMap[game.id] = game;
    });

    return gamesMap;
  }
}
