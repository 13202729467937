'use strict';

import React from 'react';
import { Link } from 'react-router'

import NoStream from './NoStream';

export default class Options extends React.Component{

    render () {
        let { stream } = this.props;
        console.log(stream);
        if ( stream.streamStation ) {
            return (
                <div className="options">
                    <h1>Welcome to Tournament Mode Stream View </h1>
                    <h2>You are viewing { stream.streamStation.name } on { stream.event.name }</h2>
                    <h3>Please choose a view</h3>
                    <ul>
                        <li><Link to={'/' + stream.streamPath + '/playing'}>Match playing now</Link></li>
                        <li><Link to={'/' + stream.streamPath + '/bracket'}>Bracket</Link></li>
                        <li><Link to={'/' + stream.streamPath + '/results'}>Results</Link></li>
                    </ul>
                </div>
            )
        }
        else {
            return <div className="Stream"><NoStream /></div>
        }
    }
}
