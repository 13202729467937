'use strict';

import React from 'react';

export default class BracketSlot extends React.Component{
    constructor (){
        super();
    }
    getClasses(slot, activePlayer){
        let classes = ['slot', slot.type];
        if ( activePlayer && activePlayer == slot.playerId ) {
            classes.push('active');
        }
        if ( slot.winner ) {
            classes.push('winner')
        }
        return classes;
    }
    handleOnMouseEnter (slot) {
        if (slot.playerName != " ") {
            this.props.passedActions.setActive(slot);
        }
    }
    handleOnMouseLeave () {
        if (this.props.bracketState.activePlayer || this.props.bracketState.activeMatch) {
            this.props.passedActions.unsetActive();
        }
    }
    render () {
        let slot = this.props.slot;
        let classes = this.getClasses(slot, this.props.bracketState.activePlayer);
        let score;
        if (this.props.bracketState.showScore) {
            let scoreText = slot.score;
            if (this.props.bracketState.showScore == 'force' && !slot.score) {
                scoreText = 0;
            }
            if (scoreText || scoreText == 0) {
                score = <span className="score">{ scoreText }</span>;
            }
        }
        return (
            <div className={ classes.join(' ') } onMouseEnter={ this.handleOnMouseEnter.bind(this, slot) } onMouseLeave={ this.handleOnMouseLeave.bind(this) }>
                <span className="player-name">{ slot.playerName }</span> { score }
            </div>
        )
    }
}
