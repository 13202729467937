import Utils from '../lib/streamUtils';

let defaultState = null;

export default function StreamReducer (state = defaultState, action) {
    switch(action.type) {
        case 'REQUEST_USER_STREAM':
            let stream = action.res.data.body;
            if (stream) {
                // decorate stream with easy to access info
                stream.streamPath = action.userId + '/' + action.streamId;
                stream.streamStation = Utils.getStation(stream);
                if (stream.streamStation) {
                    stream.streamTournament = Utils.getTournament(stream);
                    stream.streamGame = Utils.getGames()[stream.streamTournament.gameId];
                    stream.streamCurrentMatch = Utils.getCurrentMatch(stream);
                    stream.players = stream.streamTournament.players;
                    stream.playerMap = Utils.makePlayerMap(stream.streamTournament.players);
                    stream.bracket = Utils.constructBracket(stream.streamTournament);
                }
                // localStorage.setItem('stream', JSON.stringify(stream));
                state = stream;
            }
            else{
                //console.log(action);
            }
            return state;
        default:
            return state;
    }
}
