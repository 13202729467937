'use strict';

import React from 'react';

export default class NoStream extends React.Component{

    render () {
        return (
            <div className="NoStream">
                <h1>Welcome to Tournament Mode Stream View </h1>
                <h2>You have to enter an url with an user and stream</h2>
                <p>To set up a stream view, please go to your profile on <a href="http://tournamentmode.com">Tournament Mode</a></p>
                <a href="http://tournamentmode.com/docs/stream">Tournament Mode stream documentation</a>
            </div>
        )
    }
}
