'use strict';

import React from 'react';

import BracketSection from './BracketSection';

export default class BracketStage extends React.Component{
    constructor (){
        super();
    }

    render () {
        let stageClasses = 'bracket-stage bracket-stage-' + this.props.stage.type;

        return (
            <li className={stageClasses} key={this.props.stage.type} >
                <h2 className="stage-name">{this.props.stage.name}</h2>
                    { this.props.stage.sections.map((section, i) => {
                        return (
                            <BracketSection key={'section-' + i } ref={'section-' + i } section={section} bracketState={this.props.bracketState} passedActions={this.props.passedActions}/>
                        );
                    }) }
            </li>
        );
    }
}
