'use strict';

import React from 'react';

import Bracket from './Bracket';
import Loading from '../Loading';

export default class BracketView extends React.Component{
    handleClick(match){
    }
    render () {
        let { stream } = this.props.appStore;
        if(stream){
            return (
                <div className="bracket-view">
                    <Bracket bracket={ stream.bracket } filter={ this.props.params.bracketFilter } showScore={true} handleMatchClick={this.handleClick}/>
                </div>
            );
        }
        else{
            return (
                <Loading />
            )
        }
    }
}
