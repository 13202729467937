'use strict';

import React from 'react';

import BracketMatch from './BracketMatch';

export default class BracketSection extends React.Component{
    constructor (){
        super();
    }

    render () {
        let className = 'bracket-section bracket-section-' + this.props.section.type;

        return (
            <ol className={className} key={this.props.section.type} >
            { this.props.section.matches.map((match, i) => {
                return (
                    <BracketMatch key={i} ref={'match-' + match.id} match={match} bracketState={this.props.bracketState} passedActions={this.props.passedActions} />
                );
            }) }
            </ol>
        )

    }
}
