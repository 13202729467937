'use strict';
import React from 'react';
import { Router, Route } from 'react-router';
import { createHistory } from 'history';
import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';

import routes from './routes';
import * as reducers from './reducers';
import promiseMiddleware from './lib/promiseMiddleware';
import Utils from './lib/streamUtils';

let initialState = {stream: {}};
// let localStream = localStorage.getItem('stream')
//
// if (localStream) {
//     initialState.stream = JSON.parse(localStream);
//     initialState.stream.playerMap = Utils.makePlayerMap(initialState.stream.players); // Maps can't be stringified
// }

const reducer = combineReducers(reducers);
const store = applyMiddleware(promiseMiddleware)(createStore)(reducer, initialState);

React.render((
    <Provider store={store}>
        {() =>
          <Router children={routes} history={createHistory()} />
        }
    </Provider>
), document.body);
