'use strict';

import React from 'react';
import Slot from './BracketSlot';

export default class BracketMatch extends React.Component{
    constructor (){
        super();
    }

    handleMatchClick(){
        if (this.props.passedActions.handleMatchClick) {
            this.props.passedActions.handleMatchClick(this.props.match, this);
        }
    }

    render () {
        let bracketState = this.props.bracketState;
        let reactKey = 'match-' + this.props.match.id;
        let classes = ['bracket-match match'];

        if (bracketState.activeMatch && bracketState.activeMatch == this.props.match.id) {
            classes.push('active');
        }

        return (
            <li key={reactKey} className={classes.join(' ')} ref={reactKey} onClick={ this.handleMatchClick.bind(this) }>
                <div className="match-name">
                    { this.props.match.name }
                </div>
                { this.props.match.slots.map((slot, i) => {
                    return (
                        <Slot key={'slot-' + (i + 1)} slot={slot} passedActions={this.props.passedActions} bracketState={this.props.bracketState}/>
                    )
                        /*<div key={'player-' + (i + 1)} className={ classes } onMouseEnter={ this.props.slotActions.setActive.bind(this, this.props.match.slots[i]) } onMouseOut={ this.props.slotActions.unsetActive }>
                            { slot.playerName }
                        </div>*/
                })}
                <div className="match-connector" ref="connector">
                    <svg ref="connectorSVG" height="50" width="128">
                        <line x1="0" y1="1" x2="128" y2="1"
                            strokeLinecap="round"
                            ref="connectorLine"
                            strokeWidth="2"
                            stroke="orange"/>
                    </svg>
                </div>
            </li>
        );
    }
}
