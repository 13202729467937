'use strict';

import React from 'react';

export default class Results extends React.Component{
    constructor (){
        super();
    }

    render () {
        let className = 'placing';
        function propCompare(prop){
            return function (a, b) {
                if (a[prop] < b[prop]) return -1;
                if (a[prop] > b[prop]) return 1;
                return 0;
            }
        }
        let players = this.props.players;
        players.sort(propCompare('playerName'));
        players.sort(propCompare('seed'));
        players.sort(propCompare('placing'));

        return (
            <ol className={className} >
            { players.map((player, i) => {
                return (
                    <li key={'player-' + player.id }><span className="placing" >{player.placing}</span><span className="player-name">{player.playerName}</span></li>
                );
            }) }
            </ol>
        )

    }
}
