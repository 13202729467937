import React     from 'react';
import { Route } from 'react-router';

import App from './components/App';
import Options from './components/Options';
import NoStream from './components/NoStream';
import PlayingView from './components/playing/PlayingView';
import BracketView from './components/bracket/BracketView';
import ResultsView from './components/results/ResultsView';

export default (
    <Route component={App} path="/">
        <Route component={NoStream} path=":userId" />
        <Route component={Options} path=":userId/:streamId" />
        <Route component={PlayingView} path=":userId/:streamId/playing" />
        <Route component={BracketView} path=":userId/:streamId/bracket" />
        <Route component={ResultsView} path=":userId/:streamId/results" />
    </Route>
);
