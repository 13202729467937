'use strict';

import React from 'react';

import BracketStage from './BracketStage';

export default class Bracket extends React.Component{
    constructor (){
        super();
        this.state = {
            activePlayer: false,
            activeMatch: false,
            showScore: false
        };
    }

    refsToArray (ctx, prefix){
      let results = [];
      for (var refName in ctx.refs) {
          if (ctx.refs.hasOwnProperty(refName)) {
              if (refName.includes(prefix)) {
                  results.push(ctx.refs[refName]);
              }

          }
      }
      return results;
    }

    getMatchRefs (ctx) {
        let bracketMatches = new Map();
        let bracketSections = [];
        let bracketStages = ctx.refsToArray(ctx, 'stage');

        for (let section of bracketStages) {
            bracketSections = bracketSections.concat(ctx.refsToArray(section, 'section'));
        }

        for (let section of bracketSections) {
            let sectionMatches = ctx.refsToArray(section, 'match');
            for (let match of sectionMatches) {
                bracketMatches.set( match.props.match.id, match );
            }
        }
        return bracketMatches;
    }

    setActive (player) {
        if ( player.type == 'loser-of' ) {
            this.setState({
                activePlayer: false,
                activeMatch: player.previousMatch,
            });
        }
        else {
            this.setState({
                activePlayer: player.playerId,
                activeMatch: false,
            });
        }
    }
    unsetActive () {
        this.setState({
            activePlayer: false,
            activeMatch: false
        });
    }

    setConnectorHeight(match, target){
        if (target) {
            let startEl = React.findDOMNode(match).getBoundingClientRect();
            let targetEl = React.findDOMNode(target).getBoundingClientRect();
            let connectorEl = React.findDOMNode(match.refs.connector);
            let connectorSVG = React.findDOMNode(match.refs.connectorSVG);
            let connectorLine = React.findDOMNode(match.refs.connectorLine);

            let connectorSVGWidth = connectorSVG.offsetWidth;
            let hDistance = connectorEl.offsetWidth;
            let vDistance = targetEl.top - startEl.top;

            if (vDistance == 0) {
                // straight
                connectorEl.classList.add("straight");
            }
            else  {
                if (vDistance > 0) {
                    // downwards
                    vDistance = Math.floor(vDistance);
                    connectorEl.classList.add("downward");
                    connectorLine.setAttribute('y2', (vDistance - 1))
                }
                else if (vDistance < 0) {
                    // upwards
                    vDistance = Math.floor(vDistance * -1);
                    connectorEl.classList.add("upward");
                    connectorLine.setAttribute('y1', (vDistance - 1))
                }
                connectorSVG.setAttribute('width', hDistance)
                connectorLine.setAttribute('x2', hDistance)
                connectorSVG.setAttribute('height', (vDistance - 1))
            }
        }
    }

    componentDidMount(){
        this.setState({ showScore: this.props.showScore });
        let bracketMatches = this.getMatchRefs(this);
        for (let match of bracketMatches.values()) {
            let target = bracketMatches.get(match.props.match.winnersNextMatch);
            this.setConnectorHeight(match, target);
        }
    }

    render () {
        let {bracket, filter } = this.props;
        let bracketState = this.state;
        let passedActions = { unsetActive: this.unsetActive.bind(this), setActive: this.setActive.bind(this),  handleMatchClick: this.props.handleMatchClick}
        return (
            <ol className="bracket">
            {bracket.stages.map((stage, i) => {
                if ((bracket.stages.length - 2) == i) {
                    stage.type = 'semi-final';
                }
                return (
                    <BracketStage key={i} stage={stage} filter={filter} bracketState={bracketState} passedActions={passedActions} ref={'stage-' + i}/>
                );
            })}
            </ol>
        );
    }
}
