import Config from '../../../../config/client';

export default class streamUtils {
    static getStation (stream) {
        for (let station of stream.event.stations) {
            if (station.id === stream.stationId) {
                return station;
            }
        }
    }
    static getGames (stream) {
        return Config.gameMap();
    }

    static getTournament (stream) {
        let returnTournament = {};
        if (stream.streamStation) {
            for (let tournament of stream.event.tournaments) {
                if (tournament.id === stream.streamStation.tournamentId) {
                    returnTournament = tournament;
                }
            }
        }
        return returnTournament;
    }

    static getCurrentMatch (stream) {
        let players;
        let currentMatch = {
            name: '',
            players: [{name: 'Player 1', bracket: 'W'}, {name: 'Player 2', bracket: 'W'}],
            bracket: 'wb',
            swappedSides: false,
        }
        if (stream.streamStation) {
            players = this.makePlayerMap( stream.streamTournament.players );
            for (let match of stream.streamTournament.matches) {
                if (match.bracketOrder === stream.streamStation.currentMatchId) {
                    currentMatch.name = match.name;
                    match.slots.forEach((slot, i) => {
                        let player = players.get(slot.playerId);
                        if (player) {
                            currentMatch.players[i].name = player.playerName;
                            currentMatch.players[i].countryCode = player.countryCode;
                            // Get player current bracket
                            if (match.positionCoarse == 0) {
                                currentMatch.bracket = 'gf';
                                if (i && match.positionFine == 1) {
                                    currentMatch.players[i].bracket = 'L'
                                }
                                if (match.positionFine > 1) {
                                    currentMatch.players[i].bracket = 'L'
                                }
                            }
                            else if (match.positionCoarse < 0) {
                                currentMatch.bracket = 'lb';
                                currentMatch.players[i].bracket = 'L'
                            }
                        }
                        if (stream.matchData && stream.matchData.scores) {
                            currentMatch.players[i].score = stream.matchData.scores[i];
                        }
                    });
                }
            }
        }

        if(stream.matchData && stream.matchData.swappedSides){
            currentMatch.swappedSides = true;
        }

        return currentMatch;
    }

    static makePlayerMap (players) {
        let playerMap = new Map();
        for (let player of players) {
            playerMap.set(player.id, player)
        }
        return playerMap;
    }

    static constructBracket (tournament ) {
        let bracket = tournament.bracket.finals;
        let players = this.makePlayerMap( tournament.players );
        let formats = ['none', 'double', 'single', 'robin', 'swiss'];
        let sectionTypes = {
            wb: 'winners',
            lb1: 'losers-1',
            lb2: 'losers-2',
            gf1: 'grand-finals-1',
            gf2: 'grand-finals-2'
        };
        let returnBracket = {
            stages: [],
            format: formats[tournament.formatId]
        };
        bracket.stages.map((stage, i) => {
            let newStage = {
                name: stage.name,
                type: stage.type,
                sections: []
            };
            for (let key in stage) {
                if (stage.hasOwnProperty(key)) {
                    let sectionType = sectionTypes[key];
                    if (sectionType) {
                        // stage[key] is a bracket section and not name or other
                        let section = stage[key];
                        let newSection = {
                            type: sectionType,
                            matches: []
                        }
                        section.map((match) =>{
                            // loop matches in section
                            let newMatch = {
                                id: match.bracketOrder,
                                name: match.name,
                                winnersNextMatch: match.winnersNextMatch,
                                winner: match.winner,
                                slots: []
                            }
                            match.slots.map((slot, i) => {
                                // loop slots in match
                                let newSlot = {
                                    playerName: ' ',
                                    playerId: slot.playerId,
                                    type: 'bye',
                                    winner: false,
                                    previousMatch: slot.previousMatch
                                }
                                if(slot.type == 1){
                                    newSlot.type = 'player-' + (i + 1);
                                    newSlot.playerName = players.get(slot.playerId).playerName;
                                    if ( i + 1 == match.winner ) {
                                        newSlot.winner = true;
                                    }
                                }
                                else if (slot.type == 2) {
                                    newSlot.type = 'winner-of';
                                }
                                else if (slot.type == 3) {
                                    newSlot.type = 'loser-of';
                                    newSlot.playerName = 'loser of ' + slot.previousMatch;
                                }
                                if(match.matchData && match.matchData.scores){
                                    newSlot.score = match.matchData.scores[i];
                                }
                                newMatch.slots.push(newSlot);
                            });
                            newSection.matches.push(newMatch);
                        });
                        newStage.sections.push(newSection);
                    }

                }
            }
            if (newStage.sections.length > 0) {
                returnBracket.stages.push(newStage);
            }
        })
        return returnBracket;
    }
}
