import request from "axios";

const API_URL = "http://api.tournamentmode.com/v2/user/";

export function requestUserStream(userId, streamId, version) {
  let versionQuery = "";
  if (version) {
    versionQuery = "?v=" + version;
  }
  // NOTE: Sent as post to see if this resolves XSplit caching issues.
  return {
    type: "REQUEST_USER_STREAM",
    promise: request.get(
      API_URL + userId + "/stream/" + streamId + versionQuery
    ),
    userId,
    streamId
  };
}
export function gotUserStream(response) {
  return {
    type: "GOT_USER_STREAM",
    text,
    date: Date.now()
  };
}
